define(['app', '$navigator', 'queryString'], function (app, $navigator, queryString) {

  var dynamicReferralWidget = function dynamicReferralWidget() {
    var component = {};

    var _config = {
      selectors: {
        component: '[data-component=dynamicReferralWidget]',
        socialIcons: '[data-referral-social-icon]',
        socialIconsAttribute: 'data-referral-social-icon',
        socialShareLinkDesktop: '[data-referral-share-link-desktop]',
        socialShareLinkMobile: '[data-referral-share-link-mobile]',
        socialShareExpandableElement: '[data-referral-expandable-section]',
        socialShareMoreButton: '[data-js-element=dynamicReferralWidget_socialShareShowMore]',
        referralCopyButton: '[data-copy-button]',
        referralCopyInput: '[data-copy-input]',
        socialShareEmail: {
          emailModal: '[data-referral-email-modal]',
          modalClose: '[data-referral-email-modal-close]',
          modalInput: '[data-referral-email-input]',
          modalSubjectInput: '[data-referral-subject-input]',
          modalBodyInput: '[data-referral-body-input]',
          modalSend: '[data-referral-email-send]',
          modalSendClient: '[data-referral-email-client-send]',
          errorWrapper: '[data-referral-email-error]',
          error: '[data-referral-email-error-message]',
          success: '[data-referral-email-success-message]',
          successWrapper: '[data-referral-email-success]',
          successMessage: 'data-referral-email-success-email-sent',
          errorCaptchaMessage: 'data-referral-email-error-invalid-captcha',
          errorInvalidInput: 'data-referral-email-error-invalid-input',
          errorInternalMessage: 'data-referral-email-error-internal'
        }
      },
      attributes: {
        socialButtonMoreText: 'data-referral-social-share-show-more',
        socialButtonLessText: 'data-referral-social-share-show-less',
      },
      classes: {
        expandClass: 'expand',
        showClass: 'dynamicReferralWidget_showElement',
        hideClass: 'dynamicReferralWidget_hideElement'
      }
    };

    var _init = function _init(element) {
      component.element = element;

      component.showOrHideShareLinkUrls();
      component.displayTheMoreLink();
      component.bind();
      component.emailPopupReferral();

      return component;
    };

    var _emailPopupReferral = function _emailPopupReferral() {
      var queryStringParams = queryString.parse(window.location.search);
      var socialShareEmailModal = component.element.querySelector(component.config.selectors.socialShareEmail.emailModal);
      if (queryStringParams.showEmail === 'Y') {
        // Used by inward-link from the checkout-complete page
        app.element.removeClass(component.config.classes.hideClass, socialShareEmailModal);
        app.element.addClass(component.config.classes.showClass, socialShareEmailModal);
      }
    };

    var _displayTheMoreLink = function () {

      var socialShareMoreButton = component.element.querySelector(component.config.selectors.socialShareMoreButton);

      if (component.element.querySelectorAll(component.config.selectors.socialIcons).length > 1 && component.socialSharingContainerScrolls()) {
        socialShareMoreButton.classList.add(component.config.classes.showClass);
      }
    };

    var _socialSharingContainerScrolls = function(){
      var expandableElement = component.element.querySelector(component.config.selectors.socialShareExpandableElement);

      if (!expandableElement) {
        return false;
      }
      return (expandableElement.scrollHeight >= expandableElement.clientHeight + component.element.querySelector(component.config.selectors.socialIcons).clientHeight)
    }

    var _bind = function _bind() {
      const socialShareMoreButton = component.element.querySelector(component.config.selectors.socialShareMoreButton);

      component.setupCloseButtonEmail();
      component.setupSendButtonEmail();
      component.setupSendButtonClientEmail();

      component.addListener(socialShareMoreButton, 'click', component.socialButtonExpansion);

      var referralCopyButton = component.element.querySelector(component.config.selectors.referralCopyButton);
      component.addListener(referralCopyButton, 'click', component.copyReferral);
    };

    var _setupCloseButtonEmail = function _setupCloseButtonEmail() {
      var socialShareEmailModalCloseButton = component.element.querySelector(component.config.selectors.socialShareEmail.modalClose);

      if (socialShareEmailModalCloseButton) {
        component.addListener(socialShareEmailModalCloseButton, 'click', component.closeModal);
      }
    };

    var _setupSendButtonEmail = function _setupSendButtonEmail() {
      var socialShareEmailModalSend = component.element.querySelector(component.config.selectors.socialShareEmail.modalSend);

      if (socialShareEmailModalSend) {
        component.addListener(socialShareEmailModalSend, 'click', component.sendReferralEmail);
      }
    };

    var _setupSendButtonClientEmail = function _setupSendButtonClientEmail() {
      const socialShareEmailModalSend = component.element.querySelector(component.config.selectors.socialShareEmail.modalSendClient);

      if (socialShareEmailModalSend) {
        component.addListener(socialShareEmailModalSend, 'click', component.sendReferralEmailThroughClient)
      }
    }

    var _socialButtonExpansion = function _socialButtonExpansion() {
      const socialShareMoreButton = component.element.querySelector(component.config.selectors.socialShareMoreButton);
      var expandableElement = component.element.querySelector(component.config.selectors.socialShareExpandableElement);

      if (socialShareMoreButton.getAttribute('aria-expanded') === 'true') {
        app.element.removeClass(component.config.classes.expandClass, expandableElement);
        socialShareMoreButton.setAttribute('aria-expanded', 'false');
        socialShareMoreButton.innerText = socialShareMoreButton.getAttribute(component.config.attributes.socialButtonMoreText);
      } else {
        app.element.addClass(component.config.classes.expandClass, expandableElement);
        socialShareMoreButton.setAttribute('aria-expanded', 'true');
        socialShareMoreButton.innerText = socialShareMoreButton.getAttribute(component.config.attributes.socialButtonLessText);

      }
    };

    var _copyReferral = function _copyReferral() {
      var referralCopyInput = component.element.querySelector(component.config.selectors.referralCopyInput);
      referralCopyInput.select();

      try {
        document.execCommand('copy');
      } catch (err) {
        console.error('Unable to copy selected text');
      }
    };

    var _sendReferralEmailThroughClient = function _sendReferralEmailThroughClient() {
      const socialShareEmailModalInput = component.element.querySelector(component.config.selectors.socialShareEmail.modalInput);
      const socialShareEmailModalSubjectInput = component.element.querySelector(component.config.selectors.socialShareEmail.modalSubjectInput);
      const socialShareEmailModalBodyInput = component.element.querySelector(component.config.selectors.socialShareEmail.modalBodyInput);

      const emailList = socialShareEmailModalInput && socialShareEmailModalInput.value.split(/[ ,]+/).filter(Boolean) // removes  0, -0, null, false, NaN, undefined, ""
        .filter(component.validateEmail); // remove invalid email addresses
      const subject = socialShareEmailModalSubjectInput ? socialShareEmailModalSubjectInput.value : ""
      const body = socialShareEmailModalBodyInput ? socialShareEmailModalBodyInput.value : ""

      const mailingLink = "mailto:" + emailList + "?subject=" + subject + "&body=" + body

      if (emailList.length === 0) {
        const emailErrorElement = component.element.querySelector(component.config.selectors.socialShareEmail.error);
        const emailInvalidInputErrorMessage = component.element.getAttribute(component.config.selectors.socialShareEmail.errorInvalidInput);
        emailErrorElement.innerText = emailInvalidInputErrorMessage;
        component.showEmailError();
      } else {
        component.hideEmailError();
        window.open(mailingLink);
      }
    }

    var _sendReferralEmail = function _sendReferralEmail() {
      var socialShareEmailModalInput = component.element.querySelector(component.config.selectors.socialShareEmail.modalInput);

      if (socialShareEmailModalInput) {
        var emailList = socialShareEmailModalInput.value.split(/[ ,]+/).filter(Boolean) // removes  0, -0, null, false, NaN, undefined, ""
          .filter(component.validateEmail); // remove invalid email addresses

        var GOOGLE_RECAPTCHA_RESPONSE = component.element.querySelector('#g-recaptcha-response');
        GOOGLE_RECAPTCHA_RESPONSE = GOOGLE_RECAPTCHA_RESPONSE ? GOOGLE_RECAPTCHA_RESPONSE.value : '0';

        var sendData = {
          referralEmailsForm: emailList,
          googleCaptchaToken: GOOGLE_RECAPTCHA_RESPONSE
        };

        component.sendJsonAjaxPost(sendData);
      }
    };

    var _sendJsonAjaxPost = function _sendJsonAjaxPost(sendData) {
      app.ajax.post({
        url: window.location.protocol + '//' + window.location.hostname + '/sendReferralEmail.awesome',
        send: JSON.stringify(sendData),
        requestHeader: {
          header: 'Content-Type',
          value: 'application/json'
        },
        success: component.referralSentSuccessHandler,
        error: component.referralSentErrorHandler
      });
    };

    var _referralSentSuccessHandler = function _referralSentSuccessHandler(responseData) {
      var emailErrorElement = component.element.querySelector(component.config.selectors.socialShareEmail.error);
      var emailSuccessElement = component.element.querySelector(component.config.selectors.socialShareEmail.success);

      component.hideEmailError();
      //component.hideEmailSuccess();
      responseData = responseData !== null && responseData !== '' ? responseData : '{}';
      responseData = JSON.parse(responseData);

      if (responseData.response === 'INVALID_CAPTCHA') {
        var emailCaptchaErrorMessage = component.element.getAttribute(component.config.selectors.socialShareEmail.errorCaptchaMessage);
        emailErrorElement.innerText = emailCaptchaErrorMessage;
        component.showEmailError();
        return;
      }

      if (responseData.response === 'errorInputReferrals') {
        var emailInvalidInputErrorMessage = component.element.getAttribute(component.config.selectors.socialShareEmail.errorInvalidInput);
        emailErrorElement.innerText = emailInvalidInputErrorMessage;
        component.showEmailError();
        return;
      }
      if (responseData.response === 'internalError') {
        var emailInternalErrorMessage = component.element.getAttribute(component.config.selectors.socialShareEmail.errorInternalMessage);
        emailErrorElement.innerText = emailInternalErrorMessage;
        component.showEmailError();
        return;
      }

      if(responseData.response === 'emailSent') {
        var emailSuccessMessage = component.element.getAttribute(component.config.selectors.socialShareEmail.successMessage);
        emailSuccessElement.innerText = emailSuccessMessage;
        component.showEmailSuccess();
        return;
      }

      // everything went well ---> close modal, reset the input
      var socialShareEmailModalInput = component.element.querySelector(component.config.selectors.socialShareEmail.modalInput);
      if (socialShareEmailModalInput) {
        socialShareEmailModalInput.value = '';
      }

      var socialShareEmailModal = component.element.querySelector(component.config.selectors.socialShareEmail.emailModal);
      if (socialShareEmailModal) {
        app.element.removeClass(component.config.classes.showClass, socialShareEmailModal);
        app.element.addClass(component.config.classes.hideClass, socialShareEmailModal);
      }
    };

    var _showEmailError = function _showEmailError() {
      var emailErrorElementWrapper = component.element.querySelector(component.config.selectors.socialShareEmail.errorWrapper);

      app.element.removeClass(component.config.classes.hideClass, emailErrorElementWrapper);
      app.element.addClass(component.config.classes.showClass, emailErrorElementWrapper);
    };

    var _hideEmailError = function _hideEmailError() {
      var emailErrorElementWrapper = component.element.querySelector(component.config.selectors.socialShareEmail.errorWrapper);

      app.element.removeClass(component.config.classes.showClass, emailErrorElementWrapper);
      app.element.addClass(component.config.classes.hideClass, emailErrorElementWrapper);
    };

    var _showEmailSuccess = function _showEmailSuccess() {
      var emailSuccessElementWrapper = component.element.querySelector(component.config.selectors.socialShareEmail.successWrapper);

      app.element.removeClass(component.config.classes.hideClass, emailSuccessElementWrapper);
      app.element.addClass(component.config.classes.showClass, emailSuccessElementWrapper);
    };

    var _hideEmailSuccess = function _hideEmailSuccess() {
      var emailSuccessElementWrapper = component.element.querySelector(component.config.selectors.socialShareEmail.successWrapper);

      app.element.removeClass(component.config.classes.showClass, emailSuccessElementWrapper);
      app.element.addClass(component.config.classes.hideClass, emailSuccessElementWrapper);
    };

    var _referralSentErrorHandler = function _referralSentErrorHandler() {
      console.error('Failed to send email');
    };

    var _validateEmail = function _validateEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    };

    var _showOrHideShareLinkUrls = function _showOrHideShareLinkUrls() {
      var socialIcons = component.element.querySelectorAll(component.config.selectors.socialIcons);
      var OS = component.identifyMobileOS();

      for (var socialIconIndex = 0; socialIconIndex < socialIcons.length; socialIconIndex++) {
        var socialIcon = socialIcons[socialIconIndex];
        var shareLinkDesktop = socialIcon.querySelector(component.config.selectors.socialShareLinkDesktop);
        var shareLinkMobile = socialIcon.querySelector(component.config.selectors.socialShareLinkMobile);
        var socialNetworkToShare = socialIcon.getAttribute(component.config.selectors.socialIconsAttribute);

        if (socialNetworkToShare === 'messenger') {
          if (OS === 'iOS' || OS === 'Android') {
            component.hideFirstShareLinkAndShowSecond(shareLinkDesktop, shareLinkMobile);
          } else {
            component.hideFirstShareLinkAndShowSecond(shareLinkMobile, shareLinkDesktop);
          }
        }

        if (socialNetworkToShare === 'sms') {
          if (OS === 'iOS') {
            component.hideFirstShareLinkAndShowSecond(shareLinkDesktop, shareLinkMobile);
          } else if (OS === 'Android') {
            // since the sms link for android is different, backend used desktop link for android sms
            component.hideFirstShareLinkAndShowSecond(shareLinkMobile, shareLinkDesktop);
          } else {
            app.element.removeClass(component.config.classes.showClass, socialIcon);
            app.element.addClass(component.config.classes.hideClass, socialIcon);
          }
        }

        if (socialNetworkToShare === 'whatsapp' && OS !== 'iOS' && OS !== 'Android') {
          app.element.removeClass(component.config.classes.showClass, socialIcon);
          app.element.addClass(component.config.classes.hideClass, socialIcon);
        }

        if (socialNetworkToShare === 'email') {
          component.addListener(socialIcon, 'click', component.emailClickListener);
        }

        if (socialNetworkToShare !== 'email') {
          if (shareLinkDesktop) {
            component.addListener(shareLinkDesktop, 'click', component.openNewWindow);
          }

          if (shareLinkMobile) {
            component.addListener(shareLinkMobile, 'click', component.openNewWindow);
          }
        }
      }
    };

    var _openNewWindow = function _openNewWindow(event) {
      var shareUrl = this.href;
      var windowConfig = 'width=600, height=400';
      event.preventDefault();
      window.open(shareUrl, 'newwindow', windowConfig);
    };

    var _emailClickListener = function _emailClickListener(event) {
      var socialShareEmailModal = component.element.querySelector(component.config.selectors.socialShareEmail.emailModal);
      event.preventDefault();
      if (socialShareEmailModal) {
        component.hideEmailError();
        app.element.removeClass(component.config.classes.hideClass, socialShareEmailModal);
        app.element.addClass(component.config.classes.showClass, socialShareEmailModal);
      }
    };

    var _hideFirstShareLinkAndShowSecond = function _hideFirstShareLinkAndShowSecond(toBeHidden, toBeShown) {
      if (toBeHidden) {
        app.element.removeClass(component.config.classes.showClass, toBeHidden);
        app.element.addClass(component.config.classes.hideClass, toBeHidden);
      }
      if (toBeShown) {
        app.element.removeClass(component.config.classes.hideClass, toBeShown);
        app.element.addClass(component.config.classes.showClass, toBeShown);
      }
    };

    var _identifyMobileOS = function _identifyMobileOS() {
      var userAgent = $navigator.userAgent || $navigator.vendor || window.opera;

      if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) || userAgent.match(/iPod/i)) {
        return 'iOS';
      } else if (userAgent.match(/Android/i)) {
        return 'Android';
      } else {
        return 'unknown';
      }
    };

    var _closeModal = function _closeModal() {
      var socialShareEmailModal = component.element.querySelector(component.config.selectors.socialShareEmail.emailModal);
      if (socialShareEmailModal) {
        app.element.removeClass(component.config.classes.showClass, socialShareEmailModal);
        app.element.addClass(component.config.classes.hideClass, socialShareEmailModal);
      }
    };

    var _addListener = function _addListener(element, event, target) {
      if (element && event && target) {
        element.addEventListener(event, target);
      }
    };

    component.init = _init;
    component.config = _config;
    component.bind = _bind;
    component.emailPopupReferral = _emailPopupReferral;
    component.socialButtonExpansion = _socialButtonExpansion;
    component.openNewWindow = _openNewWindow;
    component.copyReferral = _copyReferral;
    component.identifyMobileOS = _identifyMobileOS;
    component.hideFirstShareLinkAndShowSecond = _hideFirstShareLinkAndShowSecond;
    component.showOrHideShareLinkUrls = _showOrHideShareLinkUrls;
    component.sendReferralEmail = _sendReferralEmail;
    component.sendReferralEmailThroughClient = _sendReferralEmailThroughClient;
    component.validateEmail = _validateEmail;
    component.referralSentSuccessHandler = _referralSentSuccessHandler;
    component.referralSentErrorHandler = _referralSentErrorHandler;
    component.emailClickListener = _emailClickListener;
    component.hideEmailError = _hideEmailError;
    component.showEmailError = _showEmailError;
    component.showEmailSuccess = _showEmailSuccess;
    component.hideEmailSuccess = _hideEmailSuccess;
    component.closeModal = _closeModal;
    component.addListener = _addListener;
    component.setupSendButtonEmail = _setupSendButtonEmail;
    component.setupSendButtonClientEmail = _setupSendButtonClientEmail;
    component.setupCloseButtonEmail = _setupCloseButtonEmail;
    component.sendJsonAjaxPost = _sendJsonAjaxPost;
    component.displayTheMoreLink = _displayTheMoreLink;
    component.socialSharingContainerScrolls = _socialSharingContainerScrolls;
    return component;
  };

  return dynamicReferralWidget;
});
